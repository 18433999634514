@import "base/helpers";

.form {
  font-family: "open sans", sans-serif;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  small {
    margin: 1.5rem auto;
    text-align: center;
  }

  label {
    display: flex;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
  }

  button {
    span {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
  }

  .error {
    color: #fb1f1f;
    font-size: 0.9rem;
    padding: 0.5rem 0;
    font-weight: bold;
  }

  .subtext {
    font-size: 0.85rem;
    line-height: 1.6rem;
  }

  [class^="input-"]:not([class^="input-group-"]) {
    border-radius: 4px;
    padding: 0.75rem 1rem;
    margin-top: 0.5rem;
    border: 1px solid #ccc;
    width: 100%;
  }

  input[type="submit"],
  button[type="submit"] {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e444a0;
    border: 1px solid #c52f86;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    min-width: 40%;
    padding: 0.9375rem 1.25rem;
    border-radius: 3rem;
    margin: 0.5rem auto;
    cursor: pointer;
    transition: background-color 0.5s ease-out;

    &:hover {
      background-color: #aa186c;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .input-select {
    appearance: none;
    background-color: transparent;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #000;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    margin: 0.5rem auto;
    padding: 0.75rem 32px 0.75rem 1rem;
    width: 100%;
  }

  .select {
    position: relative;

    &::after {
      border-left: 0.3125em solid transparent;
      border-right: 0.3125em solid transparent;
      border-top: 0.4375em solid;
      content: "";
      display: inline-block;
      height: 0;
      margin-left: 0.5em;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translate(50%, -50%);
      vertical-align: middle;
      width: 0;
    }
  }

  input:disabled {
    background-color: $clr_lightMidGrey;

    &:hover {
      cursor: not-allowed;
    }
  }

  .tox-tinymce {
    max-width: 93vw;

    @include breakpoint(phablet) {
      max-width: unset;
    }

    margin-top: 1.5rem;
  }
}
