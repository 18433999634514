@use "sass:math";
@import "base/helpers";

// Mixins ----------------------
@mixin column-common {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
}

@include component(grid-column) {
  // Variables ----------------------
  $grid-columns: 12 !default;
  $grid-gutter-width: 30px !default;
  $grid-breakpoints: sm 37.5em,
    md 50em,
    lg 64em,
    xl 70em !default;
  $grid-gutter-compensation: $grid-gutter-width * 0.5 * -1 !default;
  $grid-half-gutter-width: $grid-gutter-width * 0.5 !default;

  // Component ----------------------
  padding-left: $grid-half-gutter-width;
  padding-right: $grid-half-gutter-width;
  width: 100%;

  // Parts ----------------------
  // No parts

  // Options ----------------------
  // xs columns
  $name: xs;

  @include option(#{$name}) {
    @include column-common;

    flex-basis: auto;
  }

  @for $i from 1 through $grid-columns {
    @include option(#{$name}-#{$i}) {
      flex-basis: math.div(100%, $grid-columns) * $i;
      max-width: math.div(100%, $grid-columns) * $i;
    }
  }

  @for $i from 1 through $grid-columns {
    @include option(#{$name}-offset-#{$i}) {
      margin-left: math.div(100%, $grid-columns) * $i;
    }
  }

  @include option(#{$name}) {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  // The rest of the breakpoint sizes (sm, md, lg, xl)
  @each $breakpoint in $grid-breakpoints {
    $name: nth($breakpoint, 1);
    $size: nth($breakpoint, 2);

    @media only screen and (min-width: $size) {
      @include option(#{$name}) {
        @include column-common;

        flex-basis: auto;
      }

      @for $i from 1 through $grid-columns {
        @include option(#{$name}-#{$i}) {
          flex-basis: math.div(100%, $grid-columns) * $i;
          max-width: math.div(100%, $grid-columns) * $i;
        }
      }

      @for $i from 1 through $grid-columns {
        @include option(#{$name}-offset-#{$i}) {
          margin-left: math.div(100%, $grid-columns) * $i;
        }
      }

      @include option(#{$name}) {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
      }
    }
  }
}
