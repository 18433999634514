@import "../../base/helpers";

@include component(compare-table) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $compare-table-column-gap: 1px;
  $compare-table-column-gap-desktop: 6px;
  $compare-table-border-color: #eee;

  // Component ----------------------

  // Parts ----------------------
  @include part(checkbox) {
    opacity: 0%;
    position: absolute;
    visibility: hidden;
  }

  @include part(toggles) {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    @include breakpoint(tablet) {
      display: none;
    }
  }

  @include part(toggle) {
    border: 1px solid $clr_brandPrimary;
    display: inline-block;
    font-weight: 700;
    line-height: 1;
    max-width: 180px;
    padding: 1rem;
    text-align: center;
    width: 50%;

    &:first-child {
      background-color: #fff;
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
      color: $clr_brandPrimary;
    }

    &:last-child {
      background-color: $clr_brandPrimary;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
      color: #fff;
      pointer-events: none;
    }

    input:checked ~ .compare-table__toggles & {
      &:first-child {
        background-color: $clr_brandPrimary;
        color: #fff;
        pointer-events: none;
      }

      &:last-child {
        background-color: #fff;
        color: $clr_brandPrimary;
        pointer-events: all;
      }
    }
  }

  @include part(table) {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    // Hide fee columns
    th:nth-child(2),
    th:nth-child(n+5),
    td:nth-child(2),
    td:nth-child(n+5) {
      display: none;
    }

    // Hide model columns
    input:checked ~ & th:nth-child(2),
    input:checked ~ & th:nth-child(3),
    input:checked ~ & th:nth-child(4),
    input:checked ~ & td:nth-child(2),
    input:checked ~ & td:nth-child(3),
    input:checked ~ & td:nth-child(4) {
      display: none;
    }

    .compare-table__checkbox:checked ~ & th:nth-child(n+5),
    .compare-table__checkbox:checked ~ & td:nth-child(n+5) {
      display: table-cell;
    }

    @include breakpoint(tablet) {
      // Extra specificity required to override above `display: none;`s
      th:nth-child(1n),
      td:nth-child(1n) {
        display: table-cell;
      }
    }
  }

  @include part(thead) {
    th {
      color: #9e9e9e;
      font-size: 0.75rem;
      font-weight: 400;
      letter-spacing: -0.025rem;
      line-height: 1.1;
      padding: 0.25rem;
      text-transform: uppercase;
      vertical-align: bottom;

      @include breakpoint(phablet) {
        font-size: 0.875rem;
      }

      @include breakpoint(tablet) {
        padding: 0.5rem 0.25rem;
      }
    }
  }

  @include part(tbody) {
    tr:nth-child(odd) td {
      background-color: #fff;
    }

    tr:nth-child(even) td {
      background-color: #f3f3f3;
    }

    tr:first-child td {
      @include typography-bold;

      border-bottom: 1px solid $compare-table-border-color;
      border-top: 1px solid $compare-table-border-color;
      color: $clr_brandPrimary;
      font-weight: bold;

      &:first-child {
        border-left: 1px solid $compare-table-border-color;
      }

      &:last-child {
        border-right: 1px solid $compare-table-border-color;
      }
    }

    tr:not(:first-child) td {
      &::before {
        content: "";
        display: block;
        width: $compare-table-column-gap;
        background-color: #fff;
        border-right: 1px solid $compare-table-border-color;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        @include breakpoint(tablet) {
          width: $compare-table-column-gap-desktop;
        }
      }

      &::after {
        content: "";
        display: block;
        width: $compare-table-column-gap;
        background-color: #fff;
        border-left: 1px solid $compare-table-border-color;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;

        @include breakpoint(tablet) {
          width: $compare-table-column-gap-desktop;
        }
      }

      &:first-child::before {
        left: -1px;
        width: 1px;
      }

      &:last-child::after {
        right: -1px;
        width: 1px;
      }
    }

    td {
      font-family: $font-family-primary;
      font-size: 0.75rem;
      line-height: 1.1;
      padding: 0.75rem calc(0.25rem + #{$compare-table-column-gap} * 2);
      position: relative;
      text-align: center;
      vertical-align: middle;

      &:first-child {
        font-weight: 700;
      }

      @include breakpoint(phablet) {
        font-size: 0.875rem;
        padding: 1rem calc(0.25rem + #{$compare-table-column-gap} * 2);
      }

      @include breakpoint(tablet) {
        min-height: 60px;
        padding: 1.5rem calc(0.5rem + #{$compare-table-column-gap-desktop} * 2);
      }

      @include breakpoint(laptop) {
        font-size: 1.125rem;
      }
    }
  }

  @include part(cell) {
    &:first-child {
      width: 33%;
    }

    @include option(nowrap) {
      white-space: nowrap;
    }

    @include breakpoint(tablet) {
      @include option(wide-10) {
        width: 10%;
      }

      @include option(wide-12) {
        width: 12%;
      }

      @include option(wide-14) {
        width: 14%;
      }

      @include option(wide-28) {
        width: 28%;
      }
    }
  }

  @include part(label-extended) {
    display: none;

    @include breakpoint(tablet) {
      display: inline;
    }
  }
}
