@import "base/helpers";

@include component(page-hero) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;

  // Component ----------------------
  align-items: center;
  display: flex;
  min-height: 60vh;

  &.hide {
    display: none;
  }

  // Parts ----------------------
  @include part(inner) {
    // Minimum padding amounts
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px;
    padding-bottom: 2rem;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 2rem;
    width: 100%;
  }

  @include part(subheading) {
    margin-top: 1rem;

    .--center & {
      margin-left: auto;
      margin-right: auto;
    }

    .--right & {
      margin-left: auto;
      margin-right: 0;
    }
  }

  // storyblok options
  @include part(action) {
    // if parent has class
    .text-center & {
      justify-content: center;
    }

    .text-right & {
      justify-content: flex-end;
    }
  }

  @include part(cta) {
    margin-top: 2rem;
  }

  // Options - minHeight ----------------------
  $header-height: 68px !default;

  @include option(100vh) {
    min-height: calc(100vh - #{$header-height});
  }

  @include option(60vh) {
    min-height: calc(60vh);
  }

  @include option(short) {
    min-height: 0;

    .page-hero__inner {
      @include breakpoint(tablet) {
        padding-bottom: 2rem;
        padding-top: 2rem;
      }

      @include breakpoint(laptop) {
        padding-bottom: 2.5rem;
        padding-top: 2.5rem;
      }
    }
  }

  @include option(normal) {
    min-height: 0;

    @include breakpoint(laptop) {
      min-height: 40vh;
    }

    .page-hero__inner {
      padding-bottom: 3rem;
      padding-top: 3rem;

      @include breakpoint(phablet) {
        padding-bottom: 2rem;
        padding-top: 2rem;
      }

      @include breakpoint(tablet) {
        padding: 2.5rem;
      }

      @include breakpoint(laptop) {
        padding: 4rem;
      }
    }
  }

  // Options - textAlign ----------------------
  @include option(left) {
    text-align: left;
  }

  @include option(center) {
    text-align: center;
  }

  @include option(right) {
    text-align: right;
  }

  // Options - look ----------------------
  @include option(gradient) {
    background: linear-gradient(to right bottom, #3dc7ff, #0a6b92);
  }

  @include option(white) {
    background: white;
    color: #333;
    background-image: url("https://chuffed.org/images/hero_bg.png");
    background-size: cover;
    background-position: bottom;

    .copy {
      max-width: 100%;
      flex-direction: column;

      @include breakpoint(tablet) {
        max-width: 70%;
      }
    }

    .img {
      display: none;

      @include breakpoint(tablet) {
        display: block;
        max-width: 100%;
      }
    }

    .text-heading {
      color: #333;
      font-family: Inter, sans-serif;

      &.--size-4 {
        line-height: 1.2em;
        letter-spacing: -1px;
        font-weight: 800;
      }

      &.--size-7 {
        font-size: 1rem;
        line-height: 1.5em;
        font-weight: 500;
      }
    }
  }

  @include option(plain) {
    background: none;
    background-color: #f8f8f8;
  }

  @include option(black) {
    background: none;
    background-color: #000;
  }

  @include part(action) {
    @include breakpoint(phablet) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  @include part(cta-primary) {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    @include breakpoint(phablet) {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }

  @include part(cta-secondary) {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    @include breakpoint(phablet) {
      margin-bottom: 1rem;
      margin-left: 1rem;
      margin-top: 1rem;
    }
  }
}
