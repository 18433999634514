.form-row {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0 0;
  margin: auto;

  @include breakpoint(phablet) {
    &:not(.w-auto) {
      max-width: 600px;
    }
  }

  &.w-auto {
    width: auto;

    @include breakpoint(mobile) {
      width: 100%;
    }

    @include breakpoint(phablet) {
      width: auto;
      min-width: 600px;
    }

    .form-group {
      .form-col {
        max-width: 100%;
      }
    }
  }

  .w-600 {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;

    @include breakpoint(phablet) {
      width: 600px;
    }

    margin: auto;
  }

  .form-group {
    width: inherit;
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;

    .form-col {
      @extend .w-600;

      margin: 0 0 1rem;

      @include breakpoint(phablet) {
        margin: 0 0 1.8rem;
      }
    }
  }
}

.defaultImageSwitcher {
  span {
    text-transform: none;
    font-weight: normal;
  }
}

.preview {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  border-radius: 10px;
  animation: fadeIn 1.5s;

  .image-crop & {
    margin-bottom: 0;
  }

  &.hide {
    display: none;
  }

  @include breakpoint(desktop) {
    max-width: 720px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
