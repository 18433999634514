@import "../../../node_modules/coffeekraken-gridle/index";

// setting up the grid
@include g-setup(
  (
    context: 12,
    gutter-width: 40px,
  )
);

// make the use of media queries really easy
@include g-register-state(
  mobile,
  (
    max-width: 480px,
  )
);
@include g-register-state(
  phablet,
  (
    min-width: 481px,
    max-width: 700px,
  )
);
@include g-register-state(
  tablet,
  (
    min-width: 701px,
    max-width: 1024px,
  )
);
@include g-register-state(
  desktop,
  (
    min-width: 1025px,
  )
);

// even with full custom queries :
@include g-register-state(
  landscape,
  (
    query: "(orientation : landscape)",
  )
);

@mixin g-col($cols) {
  width: calc(8.33% * #{$cols});
  max-width: calc(8.33% * #{$cols});
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  display: inline-block;
}

@mixin g-col-prefix($cols) {
  margin-left: calc(8.33% * #{$cols});
}

@mixin g-layout($layout, $v-align: null) {

  /* stylelint-disable-next-line scss/at-if-no-null */
  @if not ($v-align == null) {
    vertical-align: $v-align;

    & > * {
      float: none !important;
    }
  }

  @if $layout == row {
    clear: both;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 0 !important;
    padding-right: 0 !important;

    &::before,
    &::after {
      content: "";
      display: table;
    }

    &::after {
      clear: both;
    }
  }
}

@mixin g-adapt {
  display: table-cell;
  white-space: nowrap !important;
  width: 1px;
  padding-left: 20px;
  padding-right: 20px;
}

@mixin g-grow {
  display: table-cell;
  width: 99999px;
  padding: 0 20px;
}
