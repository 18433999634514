@import "base/helpers";

@include component(text-html) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $clr_text: #333 !default;
  $clr_textInverse: #fff !default;
  $clr_textSubdued: #aaa !default;
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $grid-gutter-width: 30px !default;
  $grid-gutter-compensation: $grid-gutter-width * 0.5 * -1 !default;
  $grid-half-gutter-width: $grid-gutter-width * 0.5 !default;
  $media-breakout-amount: 60px !default;

  // Component ----------------------
  font-family: $font-family-primary;

  // Parts ----------------------
  // No parts - instead we're style tags directly within this component

  // Paragraphs
  p {
    color: $clr_text;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;

    @include breakpoint(tablet) {
      font-size: 1.125rem;
    }
  }

  p + p {
    margin-top: 1.5em;
  }

  // Lede style - look for first paragraph
  & > p:first-child {
    font-size: 1.125rem;
    margin-bottom: 1em;

    @include breakpoint(tablet) {
      font-size: 1.5rem;
    }
  }

  // Ordered and unordered lists
  ol,
  ul {
    color: $clr_text;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 1.5em;
    margin-top: 1.5em;

    @include breakpoint(tablet) {
      font-size: 1.125rem;
    }

    li {
      margin-bottom: 0.75em;
      margin-top: 0.75em;
    }
  }

  // Headings
  h2 {
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;

    @include breakpoint(tablet) {
      font-size: 2.25rem;
    }
  }

  h3 {
    font-size: 1.375rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;

    @include breakpoint(tablet) {
      font-size: 1.875rem;
    }
  }

  h4 {
    font-size: 1.25rem;
    margin-top: 1rem;
    margin-bottom: 0.25rem;

    @include breakpoint(tablet) {
      font-size: 1.5rem;
    }
  }

  // Images
  img {
    height: auto;
    margin-left: $grid-gutter-compensation;
    width: calc(100% + #{$grid-gutter-width});

    @include breakpoint(tablet) {
      margin-bottom: 2rem;
      margin-left: $media-breakout-amount * -1;
      margin-top: 2rem;
      width: calc(100% + #{$media-breakout-amount * 2});
    }
  }

  // Video embeds
  .video {
    margin-bottom: 1.5rem;
    margin-left: $grid-gutter-compensation;
    margin-right: $grid-gutter-compensation;
    margin-top: 1.5rem;

    @include breakpoint(tablet) {
      margin-bottom: 2rem;
      margin-left: $media-breakout-amount * -1;
      margin-right: $media-breakout-amount * -1;
      margin-top: 2rem;
    }
  }

  // Blockquotes
  // WordPress adds p tags within a blockquote
  // Style is only slightly bigger than paragraph text, as sometimes quote text can be quite long
  blockquote {
    border-left: 3px solid $clr_brandPrimary;
    margin: 0;
    margin-bottom: 1.5rem;
    margin-left: -3px;
    margin-top: 1.5rem;
    padding: 0;
    padding-left: 1rem;
    padding-right: 1rem;

    @include breakpoint(tablet) {
      border-left-width: 6px;
      margin-bottom: 2rem;
      margin-top: 2rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    > p {
      font-size: 1rem;
      font-style: italic;

      @include breakpoint(tablet) {
        font-size: 1.25rem;
      }
    }
  }

  // Slightly different sizing for paragraphs, headings, when displaying on a campaign page
  @include option(campaign) {
    p {
      color: $clr_text;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      margin: 0;

      @include breakpoint(tablet) {
        font-size: 1.125rem;
      }
    }

    p + p {
      margin-top: 1em;
    }

    // Ordered and unordered lists
    ol,
    ul {
      color: $clr_text;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1em;
      margin-top: 0;

      @include breakpoint(tablet) {
        font-size: 1.125rem;
      }

      li {
        margin-bottom: 0.25em;
        margin-top: 0.25em;
      }
    }

    // Headings
    h2 {
      font-size: 1.5rem;
      margin-top: 2rem;
      margin-bottom: 0.5rem;

      @include breakpoint(tablet) {
        font-size: 2.25rem;
      }
    }

    h3 {
      font-size: 1.375rem;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;

      @include breakpoint(tablet) {
        font-size: 1.875rem;
      }
    }

    h4 {
      font-size: 1.25rem;
      margin-top: 1rem;
      margin-bottom: 0.25rem;

      @include breakpoint(tablet) {
        font-size: 1.5rem;
      }
    }

    // Images
    img {
      height: auto;
      margin: 1rem 0;
      max-width: 100%;
    }

    // Video embeds
    .video,
    iframe {
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
      width: 100% !important;
    }

    // Blockquotes
    // Match style that's currently on the campaign page
    blockquote {
      border-left: 0.25rem solid #eee;
      font-size: 1rem;
      margin: 1rem 0 1.25rem;
      padding: 0.75rem 1.25rem;

      @include breakpoint(tablet) {
        font-size: 1.125rem;
      }
    }
  }
}
