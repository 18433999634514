@import "base/helpers";

@include component(input-group) {
  // Variables ----------------------

  $clr_brandTertiary: #ffb202 !default;
  $clr_text: #333 !default;
  $clr_textSubdued: lighten($clr_text, 90%) !default;
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial,
    sans-serif !default;
  $verticalSpacing: 1rem !default;

  // Component ----------------------

  margin-bottom: 3rem;

  // States ----------------------

  @include g-state(mobile phablet) {
    margin-bottom: 1.5rem;
  }

  // Parts ----------------------

  @include part(label-text) {
    font-weight: bold;
    font-size: 1rem;
    display: block;
    text-transform: uppercase;
    margin-bottom: $verticalSpacing * 0.25;

    .--sentence-case > .input-group__label & {
      font-weight: bold;
      font-size: 1rem;
      text-transform: none;
    }
  }

  @include part(description) {
    display: block;
    color: $clr_textSubdued;
    font-size: 0.8rem;
    margin-bottom: $verticalSpacing;
  }

  @include part(info-helper) {
    line-height: 1.2rem;
    height: 1.2rem;
    margin-left: 0.5rem;
    vertical-align: text-bottom;
    color: rgb(216, 216, 216);
    text-transform: none;
  }

  // Options ----------------------

  @include option(v2) {
    font-family: $font-family-primary;
  }

  @include option(margin-bottom) {
    &-small {
      margin-bottom: 1rem;

      &.--spacing-small {
        margin-bottom: 1rem;
      }
    }

    &-medium {
      margin-bottom: 1.5rem;
    }
  }

  @include option(small) {
    margin-bottom: 1.5rem;

    @include part(label-text) {
      font-size: 0.8em;
    }

    @include part(description) {
      margin-bottom: $verticalSpacing * 0.5;
    }
  }

  // Inline Mode
  @include option(inline) {
    @include g-layout(row, bottom);

    // Parts ----------------------

    @include part(label) {
      font-size: 0.9rem;
      text-transform: uppercase;
      vertical-align: middle;

      @include g-col(2);

      @include g-state(mobile phablet) {
        @include g-col(12);

        font-size: 1rem;
        margin-bottom: 0.9rem;
      }
    }

    // when in inline-mode the description will be
    // displayed via the tooltip
    @include part(description) {
      display: none;
    }

    @include part(inputs) {
      @include g-state(mobile phablet tablet) {
        @include g-col(12);
      }

      @include g-col(10);

      vertical-align: middle;
    }

    @include part(label-content) {
      display: flex;

      @include option(bottom-padding) {
        padding-bottom: 1rem;
      }
    }

    @include part(label-text) {
      line-height: 1.2rem;
      display: inline-block;
      vertical-align: middle;
    }

    // Inline Align
    @include option(inline--align-top) {
      @include part(label) {
        vertical-align: top;

        @include breakpoint(phablet) {
          padding-top: 0.25rem;
        }
      }

      @include part(inputs) {
        vertical-align: top;
      }
    }

    @include option(inline--align-middle) {
      @include part(label) {
        vertical-align: middle;
      }

      @include part(inputs) {
        vertical-align: middle;
      }
    }

    @include option(inline--align-bottom) {
      @include part(label) {
        vertical-align: bottom;
      }

      @include part(inputs) {
        vertical-align: bottom;
      }
    }

    // Inline Width
    @include option(inline--sixth) {
      @include part(label) {
        @include g-col(2);

        @include g-state(mobile tablet) {
          @include g-col(12);
        }
      }

      @include part(inputs) {
        @include g-col(10);

        @include g-state(mobile tablet) {
          @include g-col(12);
        }
      }
    }

    @include option(inline--quarter) {
      @include part(label) {
        @include g-col(3);

        @include g-state(mobile tablet) {
          @include g-col(12);
        }
      }

      @include part(inputs) {
        @include g-col(9);

        @include g-state(mobile tablet) {
          @include g-col(12);
        }
      }
    }

    @include option(inline--third) {
      @include part(label) {
        @include g-col(4);

        @include g-state(mobile tablet) {
          @include g-col(12);
        }
      }

      @include part(inputs) {
        @include g-col(8);

        @include g-state(mobile tablet) {
          @include g-col(12);
        }
      }
    }
  }

  @include option(label-padding) {
    > .input-group__label > .input-group__label-content {
      padding-bottom: 1rem;
    }
  }

  @include option(centered) {
    text-align: center;
  }

  @include option(spacing-none) {
    margin-bottom: 0;
  }

  @include option(spacing-xsmall) {
    margin-bottom: 0.5rem;
  }

  @include option(spacing-small) {
    margin-bottom: 1.5rem;
  }

  @include option(is-flash-highlight) {
    animation: box-pulse 1.6s ease-in 2;
  }
}

@keyframes box-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($clr_brandTertiary, 0.8);
  }

  70% {
    box-shadow: 0 0 0 10px rgba($clr_brandTertiary, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba($clr_brandTertiary, 0);
  }
}
